<template>
  <div class="home"></div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<style scoped>
  .home {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../assets/images/fundo-home.png") !important;
    background-position: center !important;
    background-size: cover !important;
    height: 100vh;
    width: 100vw;
  }
</style>
